require('team-admin')
angular.module('team-admin')

  .factory('Team', function(DS, _, snGlobals, Player, Roster, timezone, $q, $routeParams, currentUser) {
    var Team = DS.defineResource({
      name: 'teams',
      endpoint: '/v3/teams',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      actions: {
        disconnect_managed_team: {
          method: 'POST'
        }
      },
      methods: {
        activeSeason: activeSeason,
        clearRosterCache: clearRosterCache,
        canManage: canManage,
        getRoster: getRoster,
        isAdmin: isAdmin,
        isGameAdmin: isGameAdmin,
        isEventAdmin: isEventAdmin,
        isRosterAdmin: isRosterAdmin,
        rosteringRequiresRegistration: rosteringRequiresRegistration,
        roster: roster
      },
      beforeInject: parseSeasons,
      afterUpdate: parseSeasons,
      afterCreate: parseSeasons,
      relations: {
        hasMany: {
          rosters: {
            localField: 'rosters',
            foreignKey: 'team_id'
          }
        }
      },
      computed: {
        color: ['primary_color', color],
        imgUrl: ['headshot', imgUrl],
        nginTeamId: ['extended_attributes', xa => _.find(xa, { key: 'ngin_team_id' })?.value],
        isNginOriginator: ['originator_system', 'originator_type', (os, ot) => os === 'Ngin' && ot === 'team_instance'],
        sportIcon: ['sport_key', sportIcon]
      }
    })

    function color(primary_color) {
      return primary_color
    }

    function imgUrl(headshot) {
      return _.get(headshot, 'image_urls.large', null)
    }

    function sportIcon(sport_key) {
      return _.kebabCase(sport_key)
    }

    function canManage() {
      return this.userIsAdmin
    }

    function isAdmin() {
      return this.userIsAdmin
    }

    function isGameAdmin() {
      return currentUser.hasRoleForResource('game_admin', 'Team', this.id)
    }

    function isEventAdmin() {
      return currentUser.hasRoleForResource('event_admin', 'Team', this.id)
    }

    function isRosterAdmin() {
      return currentUser.hasRoleForResource('roster_admin', 'Team', this.id)
    }

    function parseSeasons(opts, data, cb) {
      var arr = Array.isArray(data) ? data : [data]
      arr.forEach(team => _.defaults(team, {
        timezone: timezone.current.name,
        timezone_identifier: timezone.current.name
      }))
      if (cb) cb(null, data)
    }

    // Build a stand-in object for this from TS data for compatibility with shared components.
    function activeSeason() {
      return {
        name: _.compact([this.program_primary_text, this.program_secondary_text]).join(' | '),
        season_team_id: /* this.isNginOriginator ? +this.originator_id : */ this.id // TODO: remove originator_id when mobile swaps to TS id
      }
    }

    function rosteringRequiresRegistration() {
      var season = _.first(this.seasons)
      return season && season.league && season.league.rostering_requires_registration === true
    }

    // cache roster players

    function roster() {
      return this.roster._data = this.roster._data || []
    }

    var rosterUpdated = false

    function clearRosterCache() {
      rosterUpdated = true
    }

    function getRoster() {
      var team = this
      var roster = team.roster()
      var useCache = roster.teamId === this.id && !rosterUpdated
      rosterUpdated = false

      // return from cache if possible
      if (useCache) return $q.when(roster)


      // Update the roster object
      roster.length = 0
      _.extend(roster, {
        teamId: this.id,
        loading: true
      })

      // load the main payload
      return loadRoster.call(team)
        .then(appendToRoster.bind(team))
        .then(() => roster.loading = false)
        .then(() => roster)
    }

    function appendToRoster(data) {
      var roster = this.roster()
      var arr = [].concat(data)
      _.each(arr, item => roster.push(item))
    }

    function loadRoster(opts) {
      return this.rosters[0].DSRefresh(opts).then(_.property('roster_personas'))
    }

    Team.v2Options = {
      endpoint: '/teams',
      basePath: DS.adapters.ngin_v2.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'ngin_v2'
    }

    return Team
  })

  .factory('Opponent', function(DS, Team, paginate) {
    var Opponent = DS.defineResource({
      name: 'opponents',
      endpoint: '/v3/teams/opponents', // use options to inject team ID here
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      useClass: Team
    })

    return paginate(Opponent)
  })
