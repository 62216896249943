require('team-admin')
angular.module('team-admin')
  .directive('rosterList', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./roster-list.html'),
      controller: function($q, $scope, $timeout, $log, selectHelper, dialog, i18ng, Player, AlertPreference, Alerts, currentUser, ENV, listFilter, filterFilter, orderByFilter, $window, Position, renderContext, SNAP, setWhile, printService, printedCardService, $routeParams, Policy, launchDarklyFlags, $location) {

        var sortOrder = 'first_name,last_name'
        var unwatchType // set in switchType
        var unwatchLoadError // set in switchType

        var $ctrl = this
        $ctrl.$onInit = $onInit

        $scope.scope = $scope
        $scope.ENV = ENV
        $scope.SNAP = SNAP
        $scope.searchTerm = ''
        $scope.selectedPlayers = selectHelper.bind($scope, 'filteredPlayers')
        $scope.editMode = false
        $scope.permissionActions = ['update_team', 'roster', 'add_game', 'add_event', 'score_game', 'grant_permissions']
        $scope.teamPermissions = launchDarklyFlags.teamPermissions

        function $onInit() {
          if ($routeParams.printRoster) {
            $scope.print('roster')
          }
          else if ($routeParams.printContactList) {
            $scope.print('contactList')
          }
        }

        // LOAD DATA

        function otherTerm() {
          return renderContext.roster.next
        }

        function playerLoadErrorModal(loadError) {
          if (!loadError) return

          dialog.confirm({
            directive: 'confirm-modal',
            attrs: {
              'message': $scope.CONTEXT + '.LOAD_ERROR.message',
              'messageHelp': $scope.CONTEXT + '.LOAD_ERROR.message_help',
              'type': 'error',
              'cancelButton': $scope.CONTEXT + '.LOAD_ERROR.cancel_button',
              'confirmButton': $scope.CONTEXT + '.LOAD_ERROR.confirm_button'
            }
          })
            .then($window.location.reload.bind($window.location))
        }

        function update([team, context]) {
          if (!team || !context) return

          $scope.context = context
          $scope.loadingProp = context + 'Loading'
          $scope.CONTEXT = context.toUpperCase()
          $scope.gaLabelType = context.charAt(0).toUpperCase() + context.slice(1)

          Position.findAll({ team_id: team.id }).then($scope.setAs('positions'))

          team.getRoster()
            .then($scope.setAs('roster'))
            .then(updateFilteredPlayers)
        }

        function loadAlertPreferences() {
          $scope.alertPreferencesLoading = true
          AlertPreference.getMessagesEnabled($scope.team, $scope.roster)
            .then($scope.setAs('messagesEnabled'))
            .catch($log.error)
            .finally($scope.setAs('alertPreferencesLoading', false))
        }

        $scope.loadPolicyPrincipalChecks = function() {
          if (!$scope.teamPermissions || !$scope.team.canGrantPermissions()) return
          $scope.principalPolicyChecksLoading = true
          $q.all(_.map(
            _.filter($scope.roster, { roster_role: 'staff' }),
            staff => Policy.check_principal(
              null,
              {
                data: {
                  principal: { id: staff.persona_id, type: 'persona' },
                  resource: { id: $scope.team.id, type: 'team' },
                  actions: $scope.permissionActions,
                  context: { org_id: $scope.team.org_id }
                }
              }
            )
          ))
            .then(principalPolicyChecks => _.indexBy(principalPolicyChecks, 'principal.id'))
            .then($scope.setAs('principalPolicyChecks'))
            .catch($log.error)
            .finally($scope.setAs('principalPolicyChecksLoading', false))
        }

        function loadTypeComplete() {
          var prop = $scope.loadingProp
          var roster = $scope.roster || {}
          return prop + roster[prop]
        }

        $scope.$watch(loadTypeComplete, updateFilteredPlayers)
        $scope.$watchCollection('roster', function() {
          if (!loadTypeComplete()) return

          updateFilteredPlayers()
          loadAlertPreferences()
          $scope.loadPolicyPrincipalChecks()
        })
        $scope.$watchGroup(['team', 'renderContext.roster.next'], update)


        // FILTER / SEARCH / SELECT

        function searchFn(player) {
          var query = $scope.searchTerm.toLowerCase()
          if (!query) return true
          var searchText = (player.fullName() + (player.jersey_number || '')).toLowerCase()
          return searchText.indexOf(query) !== -1
        }

        function updateFilteredPlayers() {
          var context = renderContext.roster.next
          var rosterType = Player.contextTypeV3[context]
          var playersOfType = _.where($scope.roster, { roster_role: rosterType })
          var filtered = filterFilter(playersOfType, searchFn)
          var order = sortOrder.split(/,\s*/)
          $scope.filteredPlayers = orderByFilter(filtered, order)
          $scope.hiddenCount = playersOfType.length - filtered.length
          $scope.emptyState = !playersOfType.length
          $scope.noResults = !filtered.length && $scope.hiddenCount
        }

        $scope.$watch('searchTerm', updateFilteredPlayers)


        // MODALS
        $scope.allowRostering = function() {
          var context = renderContext.roster.next
          return context == 'staff' || !$scope.team.rosteringRequiresRegistration()
        }

        $scope.addPlayersModal = function() {
          dialog.confirm({
            directive: renderContext.roster.next === 'staff' ? 'edit-player' : 'add-players',
            attrs: {
              team: $scope.team,
              context: renderContext.roster.next,
              gaCategory: 'Roster',
              gaLabelType: $scope.gaLabelType
            }
          })
            .then(function(createdPlayers, notInvitedPlayers) {

              var roster = $scope.roster
              var rosterUpdated
              createdPlayers = [].concat(createdPlayers) // edit returns one

              _.each(createdPlayers, function(player) {
                if (!_.contains(roster, player)) {
                  roster.push(player)
                  rosterUpdated = true
                }
              })

              if (rosterUpdated) updateFilteredPlayers()

              alertNames('success', createdPlayers, 'ADD.success')
              alertNames('warning', notInvitedPlayers, 'ADD.not_invited')

              if (renderContext.roster.next === 'staff') { // newly created staff needs side panel open to assign team permissions
                const teamId = createdPlayers[0].team.id
                const playerId = createdPlayers[0].id

                $location.path(`/teams/${teamId}/staff/${playerId}`)

                const watcher = $scope.$watch( 
                  function() {
                    return document.getElementById('rosterPersonaTeamPermissionsCard')?.shadowRoot.getElementById(`editTeamPermissionsButton-${playerId}`)
                  }, 
                  function() { 
                    const element = document.getElementById('rosterPersonaTeamPermissionsCard')?.shadowRoot.getElementById(`editTeamPermissionsButton-${playerId}`)
                    if (element) {
                      element.click()
                      watcher() // this will destroy $watch after element.click()
                    }
                  }
                )
              }
            })
        }

        $scope.removePlayersModal = function() {
          dialog.confirm({
            directive: 'remove-players',
            attrs: {
              team: $scope.team,
              players: $scope.selectedPlayers,
              context: renderContext.roster.next,
              gaCategory: 'Roster',
              gaLabelType: $scope.gaLabelType
            }
          })
            .then($scope.removePlayers)
        }

        $scope.removePlayers = function(deletedPlayers) {
          var roster = $scope.roster
          var rosterUpdated

          _.each(deletedPlayers, function(player) {
            var playerInGroup = _.findWhere(roster, { id: player.id })
            var rosterIndex = roster.indexOf(playerInGroup)
            if (rosterIndex >= 0) {
              roster.splice(rosterIndex, 1)
              rosterUpdated = true
            }
          })

          if (rosterUpdated) updateFilteredPlayers()

          alertNames('success', deletedPlayers, 'REMOVE.success')
        }

        $scope.newMessageModal = function() {
          dialog.confirm({
            directive: 'new-team-message',
            attrs: {
              team: $scope.team,
              roster: $scope.roster,
              recipients: _.map($scope.selectedPlayers, 'persona.persona_id'),
              messagesEnabled: $scope.messagesEnabled
            }
          })
        }

        $scope.printMenuItems = {
          sections: [
            {
              menuItems: _.compact([
                {
                  text: i18ng.t('Roster'),
                  action: function() { $scope.print('roster') }
                },
                {
                  text: i18ng.t('Contact List'),
                  action: function() { $scope.print('contactList') }
                }
              ].concat(_.flatten(_.map($scope.$root.statNginTeams, function(snTeam) {
                var items = [
                  {
                    text: snTeam.root_organization_name + ' ' + i18ng.t('Roster'),
                    action: function() { $scope.print('ngbRoster', snTeam) }
                  }
                ]
                if (snTeam.print_cards) {
                  items.push({
                    text: snTeam.root_organization_name + ' ' + i18ng.t('Player Cards'),
                    action: function() { $scope.print('cards', snTeam) }
                  })
                }
                if (_.size($scope.$root.statNginTeams) > 1) {
                  items.forEach(function(item) {
                    item.subText = snTeam.program_name
                  })
                }
                return items
              }))))
            }
          ]
        }

        $scope.print = setWhile($scope, 'printing', function(content, data) {
          switch (content) {
            case 'roster':
              return printService.printContent({
                component: 'print-roster',
                margin: .25,
                attrs: {
                  team: $scope.team
                }
              })
            case 'contactList':
              return printService.printContent({
                component: 'print-contact-list',
                margin: .25,
                landscape: true,
                attrs: {
                  team: $scope.team
                }
              })
            case 'ngbRoster':
              return $q(function(resolve) {
                $window.addEventListener('message', function handler(event) {
                  if (_.get(event, 'data.type') !== 'print:complete') return
                  $window.removeEventListener('message', handler)
                  resolve()
                })
                $window.open(ENV.urls.hq + '/program-print-roster?teamID=' + data.id + '&set_current_org_id=' + data.org_id + '#externalRoute&externalNav', 'hidden')
              })
            case 'cards':
              return printedCardService.print({
                user_uuid: currentUser.uuid,
                issuing_organization_id: data.root_organization_id,
                affiliation_organization_id: data.org_id,
                roster_id: data.roster_id,
                season_id: data.season_id,
                team_instance_id: +$scope.team.originator_id,
                scope: 'team'
              })
          }
        })

        $scope.editMenuItems = {
          sections: [
            {
              menuItems: [
                {
                  text: i18ng.t('PLAYERS.ADD.title'),
                  action: $scope.addPlayersModal
                },
                {
                  text: i18ng.t('PLAYERS.EDIT.title'),
                  action: () => {
                    $scope.selectedPlayers.clearAll()
                    $scope.editMode = true
                  }
                },
              ]
            }
          ]
        }

        var changedPlayers = () => _.filter($scope.filteredPlayers, p => p.DSHasChanges())
        var exitEdit = () => $scope.editMode = false
        function flashShadow(player, $shadowColor) {
          player.$shadowColor = $shadowColor
          $timeout(() => delete player.$shadowColor, 2000)
        }
        $scope.updateName = player => player.name = `${ player.first_name } ${ player.last_name }`

        $scope.cancelChanges = function() {
          _.invoke(changedPlayers(), 'DSRevert')
          exitEdit()
        }

        $scope.saveChanges = function() {
          var updatePlayers = changedPlayers()
          return $q.all(_.map(updatePlayers, player => player.DSSave({ ...Player.v3Options, omit: ['roster_id'] })
            .then(() => flashShadow(player, '#00a846'), () => flashShadow(player, '#b42846'))))
            .then(() => {
              var failedUpdates = changedPlayers()
              var updated = _.difference(updatePlayers, failedUpdates)
              alertNames('success', updated, 'EDIT.success')
              alertNames('error', failedUpdates, 'EDIT.error') || exitEdit()
            })
        }

        function alertNames(type, players, translationSubkey) {
          var count = players?.length
          if (count) {
            Alerts[type](listFilter(players, `${ $scope.CONTEXT }.${ translationSubkey }`, {
              count: count,
              format: 'fullName',
              other_term: otherTerm()
            }))
          }
          return !!count
        }
      }
    }
  })
