require('team-admin')
angular.module('team-admin')
  .factory('Event', function($rootScope, DS, getEnum, paginate, listFilter, moment, timezone, i18ng, _, Venue, Subvenue) {

    const recurMap = { daily: 'day', weekly: 'week', monthly: 'month', null: 'none', undefined: 'none' }

    // Set temporary date/time properties (used for template bindings)
    function setTimeData(event) {
      var tz = event.local_timezone ||= timezone.current.name
      var startDateTime = moment.tz(event.start_date_time, tz)
      var endDateTime = moment.tz(event.end_date_time, tz)
      var timeFormat = 'HH:mm'
      var dateFormat = 'YYYY-MM-DD'
      if (!event.id) {
        var defaultStartTime = moment().add(1, 'hours').startOf('hour')
        event.start_time = defaultStartTime.format(timeFormat)
        event.end_time = defaultStartTime.add(1, 'hours').format(timeFormat)
      }
      else {
        event.start_time = startDateTime.format(timeFormat)
        event.end_time = endDateTime.format(timeFormat)
      }
      event.date = startDateTime.format(dateFormat)
    }

    // Normalize rucurring event data
    function setRecurData(event) {
      var ri = event.recur_info ||= {}
      event.ends = !!ri.end_date_time
      var int = event.recurInterval = recurMap[ri.frequency]

      if (int === 'week' && ri.interval === 2) {
        event.recurInterval = 'week2'
      }
      if (int === 'month') {
        ri.month_repeat_by = ri.month_day ? 'day_of_month' : 'day_of_week'
      }
    }

    function parse(event) {
      setTimeData(event)
      setRecurData(event)
      event.venue_id = +event.venue_id || null
      event.subvenue_id = +event.subvenue_id || null
    }

    function beforeSave(options, data, cb) {
      data.type = 'event' // needs type instead of event_type for POST/PUT
      delete data.game_details // CS bombs if this is passed in during PUT for an event... even though it gives it to us in the GET

      // update date properties
      data.tbd_time ||= false
      data.with_notification ||= false

      var dates = Event.getDates(data)
      var { startDateObj, endDateObj } = dates
      _.extend(data, _.pick(dates, 'start_date_time', 'end_date_time'))

      // 'location_address' is required if 'location_url' is being sent
      if (data.location_url && !data.location_address) data.location_address = i18ng.t('SCHEDULE.EVENT.default_location_name')

      // clear recurring if not a repeating event
      if (data.recurInterval === 'none') {
        delete data.recur_info
        delete data.event_series_id
      }

      // update recurring properties if needed
      else {
        var ri = data.recur_info = angular.copy(data.recur_info) // don't update in-place, in case of an error

        ri.start_date_time = dates.start_date_time
        ri.end_date_time = dates.recurEndDate
        ri.duration_min = endDateObj.diff(startDateObj, 'm')

        // handle bi-weekly case
        ri.interval = (data.recurInterval === 'week2') ? 2 : 1
        if (data.recurInterval === 'week2') data.recurInterval = 'week'

        // strip days array if not needed
        if (data.recurInterval !== 'week') {
          _.each(getEnum('SCHEDULE.DAY'), ({ label, value }) => _.set(ri, ['day', value], false))
        }

        if (data.recurInterval === 'month') {
          var dayNum = +startDateObj.format('D')
          switch (ri.month_repeat_by) {
            case 'day_of_month':
              ri.month_day = dayNum
              ri.day_step = null
              break
            case 'day_of_week':
              ri.day[startDateObj.format('dddd').toLowerCase()] = true
              ri.day_step = Math.ceil(dayNum / 7) % 5 || -1 // last (-1) instead of 5
              ri.month_day = null
              break
          }
          delete ri.month_repeat_by
        }

        ri.frequency = _.invert(recurMap)[data.recurInterval]
      }


      cb(null, data)
    }

    function beforeInject(options, data) {
      var arr = Array.isArray(data) ? data : [data]
      arr.forEach(parse)
    }

    function beforeCreateInstance(options, data) {
      parse(data)
    }

    // Updating a recurring event returns an event with a new id... js data hates this
    // so we preserve the data we sent and rely on the view to handle the change.
    function afterSave(options, data, cb) {
      // data = options.preservedInstanceData || data
      // if (options.saveInstance) $rootScope.$emit('event:save_recurring', data)
      // cb(null, options.preservedInstanceData || data)
      parse(data)
      cb(null, data)
    }

    // function afterInject(options, data) {
    //   // this cannot seem to be attached in any other way. It gets
    //   // stripped when trying to add it as a method and gets replaces
    //   // when adding it to the resource definition directly.
    //   data.DSDestroy = DSDestroy
    // }

    // function DSDestroy(options) {
    //   if (this.recurInterval !== 'none') {
    //     options = options || {}
    //     options.method = 'PUT'
    //     options.params = options.params || {}
    //     _.defaults(options.params, {
    //       change_range: 'one',
    //       change_day: this.date
    //     })
    //   }
    //   return Event.destroy(this.id, options)
    // }

    var methods = {

      shortTitle: function() {
        return this.title
      },

      unspecifiedTime: function() {
        return this.tbd_time || this.all_day_event
      },

      unspecifiedTimeLabel: function() {
        return this.all_day_event ? 'SCHEDULE.all_day' : 'SCHEDULE.tbd_time'
      },

      locationDisplay: function(format = 'short') {
        return _.compact([this.location_name || this.location_address, this.location_description]).join(' - ') +
          (format === 'long' && this.location_name ? '\n' + this.location_address : '')
      }
    }

    var Event = DS.defineResource({
      name: 'events',
      methods: methods,
      endpoint: '/v3/events',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      computed: {
        event_id: ['id', _.identity],
        unique_id: ['id', _.identity],
        contextName: ['id', _.constant('events')],
        gaLabelName: ['id', _.constant('Event')],
        contextParams: ['id', id => ({ eventId: id })],
        eventStatus: ['status', _.identity],
        recurText: ['local_timezone', 'recurInterval', 'start_date_time', 'recur_info', (tz, int, date, ri) => {
          if (!ri || int === 'none') return ''
          var endDate = moment.tz(ri.end_date_time, tz).format('MMM D YYYY')
          var key = int, opts = {}
          if (int === 'month') key += `_${ ri.month_repeat_by }`
          switch (key) {
            case 'week':
            case 'week2':
              var days = i18ng.t('SCHEDULE.DAY', { returnObjectTrees: true })
              days = _.map(_.intersection(_.keys(days), _.keys(_.pick(ri.day, Boolean))), key => days[key]) // intersection to order them
              opts.days = listFilter(days, { limit: 7 })
              break
            case 'month_day_of_month':
              opts.dayOfMonth = moment.tz(date, tz).format('Do')
              break
            case 'month_day_of_week':
              opts.ordinalDayCount = i18ng.t(`SCHEDULE.ORDINAL.${ ri.day_step }`)
              opts.day = moment.tz(date, tz).format('dddd')
              break
          }
          return i18ng.t('SCHEDULE.RECUR_DESCRIPTION.wrap', { rangeText: i18ng.t(`SCHEDULE.RECUR_DESCRIPTION.${ key }`, opts), endDate })
        }],
        canDelete: [() => true] // mirrors game computed property... TODO: expand logic if we ever add affiliate events
      },
      relations: {
        hasOne: {
          venues: {
            localField: 'venue',
            localKey: 'venue_id'
          },
          subvenues: {
            localField: 'subvenue',
            localKey: 'subvenue_id'
          }
        },
        hasMany: {
          event_rsvps: {
            localField: 'event_attendees',
            foreignKey: 'event_id'
          }
        }
      },
      omit: ['recurInterval', 'date', 'end_time', 'start_time', 'ends', 'event_id', 'unique_id', 'contextName', 'gaLabelName', 'contextDetail', 'place', 'venue_address'], // strip temporary properties before save
      beforeInject: beforeInject,
      beforeUpdate: beforeSave,
      beforeCreate: beforeSave,
      beforeCreateInstance: beforeCreateInstance,
      afterCreate: afterSave,
      afterUpdate: afterSave,
      // afterInject: afterInject
    })

    Event.parseCalendar = Event.inject
    Event.setTimeData = setTimeData

    Event.setupTranslation = function(scope) {
      scope.recur_info = getEnum('SCHEDULE.RECUR')
      scope.recurIntervals = getEnum('SCHEDULE.RECUR_INTERVAL')
      scope.days = getEnum('SCHEDULE.DAY')
      scope.dayAbbrevs = getEnum('SCHEDULE.DAY_ABBREV')
      scope.dayKeys = _.keys(scope.days)
      scope.eventStatuses = getEnum('ENUMS.event_statuses')
    }

    Event.getDates = function(data) {
      var tz = data.local_timezone ||= timezone.current.name
      // set start time to midnight for all day events
      var startTime = data.all_day_event ? '00:00' : data.start_time
      var startDateObj = moment.tz(data.date + ' ' + startTime, tz)
      var endDateObj = moment.tz(data.date + ' ' + (!data.all_day_event && data.end_time ? data.end_time : '23:59:59'), tz)
      var start_date_time = startDateObj.format() || null
      var end_date_time = endDateObj.format() || null

      if (data.recur_info?.end_date_time) {
        var recurEndDate = moment.tz(data.recur_info.end_date_time, tz)
          .startOf('day')
          .add(moment.duration(startTime))
          .format()
      }

      return { tz, startTime, startDateObj, endDateObj, start_date_time, end_date_time, recurEndDate }
    }

    return paginate(Event)
  })
