angular.module('app-permission', [])

  .service('appPermission', function($q, $rootScope, $route, $routeParams, renderContext, showError, CurrentUserService, Team, featureToggles, launchDarklyFlags, timezone, Policy) {

    var teamId
    var appPermission = { canUseApp: false, loaded: false, $oauthCallback: false }

    function update() {
      if (renderContext.layout === 'error' || !$route.current) return
      if (
        /^\d+$/.test($routeParams?.teamId || '') ||
        (teamId && $routeParams.teamId === teamId)
      ) return

      CurrentUserService.find()
        .then(function(user) {
          if (!user || !user.id) return OAuthState.initiateLogin('teams')

          if (
            $route.current && $route.current.$$route &&
            $route.current.$$route.appPermission === 'oauthCallback'
          ) return appPermission.$oauthCallback = true
        })
        .then(function() {
          $q.all([...getTeams(), featureToggles.promise, launchDarklyFlags.promise])
            .then(checkPermissions)
            .catch(showError)
            .finally(done)
        })
    }

    function getTeams() {
      teamId = $routeParams.teamId
      if (!teamId) return $q.reject(new Error('No team id found.'))
      return [
        Team.find(teamId),
        Team.findAll({
          primary: true,
          team_id: teamId
        }, { endpoint: '/v3/teams/mine', bypassCache: true, cacheResponse: false, headers: { 'Cache-Control': 'max-age=0, no-cache, no-store, must-revalidate' } })
      ]
    }

    function checkPermissions([team, [participantTeam]]) {
      if (!featureToggles.team_management) return showError('403')

      return Policy.check(null, { data: {
        resource: { type: 'team', id: team.id },
        actions: '*',
        context: { org_id: team.org_id }
      } })
        .then(policy => {
          if (!policy.actions.view_team_center) {
            if (!featureToggles.team_center_for_participants) return showError('403')
            if (!participantTeam) return showError('403')
          }

          timezone.set(team.timezone || timezone.browserDefault)
          team.policyActions = policy.actions
          appPermission.canUseApp = true
        })
    }

    function done() {
      appPermission.loaded = true
    }

    $rootScope.$on('$routeChangeSuccess', update)
    update()

    return appPermission
  })
  .run(function($rootScope, appPermission) {
    $rootScope.constructor.prototype.appPermission = appPermission
  })
